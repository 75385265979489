// src/components/App.js
import React, { useState } from 'react';
import axios from 'axios';

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://rbl_login.nbdapi.workers.dev/', {
                username,
                password
            });

            console.log('Login successful:', response);
        } catch (error) {
            console.error('Login failed:', error.message);
        }
    };

    return (
        <div>
        <h1>Login Page</h1>
            <label>
                Username:
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <br />
            <label>
                Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <br />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
}

export default App;
